import React, { useState } from 'react';

const AccordionItem = ({ title, content, isOpen, onToggle }) => {
    return (
        <div className="border-b border-white border-opacity-10">
            <button
                className="w-full py-4 px-6 flex justify-between items-center hover:bg-white hover:bg-opacity-5 transition-colors duration-300"
                onClick={onToggle}
                aria-expanded={isOpen}
            >
                <span className="text-white text-left font-medium">{title}</span>
                <svg
                    className={`w-6 h-6 text-white transform transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                >
                    <path d="M19 9l-7 7-7-7" />
                </svg>
            </button>
            <div 
                className={`accordion-content ${isOpen ? 'open' : ''}`}
                aria-hidden={!isOpen}
            >
                <div className="px-6 py-4 text-white text-opacity-90">
                    {content}
                </div>
            </div>
        </div>
    );
};

const AccordionComponent = ({ items }) => {
    const [openIndex, setOpenIndex] = useState(null);

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="bg-black bg-opacity-50 backdrop-blur-md overflow-hidden">
            {items.map((item, index) => (
                <AccordionItem
                    key={index}
                    title={item.title}
                    content={item.content}
                    isOpen={openIndex === index}
                    onToggle={() => handleToggle(index)}
                />
            ))}
        </div>
    );
};

export default AccordionComponent;