import React, { useState, useRef } from 'react';
import ArrowIcon from '../../svg/arrow-icon/ArrowIcon';

const SubmenuComponent = ({ title, links, isOpen }) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleToggle = () => {
        if (isOpen) {
            setDropdownOpen(!isDropdownOpen);
        }
    };

    const handleMouseEnter = () => {
        if (!isOpen) {
            setDropdownOpen(true);
        }
    };

    const handleMouseLeave = () => {
        if (!isOpen) {
            setDropdownOpen(false);
        }
    };

    return (
        <div
            className="relative"
            ref={dropdownRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div
                className={`group justify-between p-5 flex items-center cursor-pointer select-none`}
                onClick={handleToggle}
            >
                {title}
                <div className={`ml-2 transform transition-transform duration-500 ${isDropdownOpen ? 'rotate-180' : ''}`}>
                    <ArrowIcon />
                </div>
            </div>
            {isDropdownOpen && (
                <div className={`submenu-dropdown ${isOpen ? 'mobile px-2.5' : 'desktop'}`}>
                    <div role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        {links.map((link, index) => (
                            <a key={index} href={link.href} className="block py-4 px-3 text-left text-sm hover:bg-gray-800" role="menuitem">
                                {
                                    isOpen && (
                                        '- '
                                    )
                                }
                                {link.text}
                            </a>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SubmenuComponent;