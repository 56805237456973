import React, { useRef, useEffect } from 'react';
import './BannerComponent.css';

const BannerComponent = ({ title = "IDCD" }) => {
    const bannerRef = useRef(null);

    useEffect(() => {
        const handleMouseMove = (event) => {
            const banner = bannerRef.current;
            const rect = banner.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;
            banner.style.setProperty('--mouse-x', `${x}px`);
            banner.style.setProperty('--mouse-y', `${y}px`);
        };

        const banner = bannerRef.current;
        banner.addEventListener('mousemove', handleMouseMove);

        return () => {
            banner.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <div className="bg-primary pt-32 pb-20 h-auto relative">
            <div className="h-full flex items-center justify-center w-full">
                <div className="w-full px-4 mx-auto items-center relative">
                    <h1 
                        ref={bannerRef} 
                        className="banner-text text-center select-none w-full 
                                   text-9xl lg:text-[16rem] 
                                   leading-none"
                    >
                        {title}
                    </h1>
                    <div className="text-center text-lg lg:text-3xl text-text font-light opacity-80 select-none">
                        Initiative for Diabetes Cyber Defence
                    </div>
                    {/** thin line */}
                    <div className="w-[5.5em] h-[1px] bg-text opacity-50 my-4 text-center mx-auto"></div>
                </div>
            </div>
        </div>
    );
};

export default BannerComponent;