import React from 'react';
import PageSectionComponent from '../../../components/page-section-component-box/PageSectionComponentBox';
import PageTitle from '../../../components/page-title/PageTitle';

const SensorsLayer = () => {
    return (
        <>
            {
                /* 
                 * 
                 * Page title.
                 * 
                */
            }
            <PageTitle title="Sensors Layer" />

            <div>

                <PageSectionComponent title="Researcher Map" subtitle="Researchers Section" longtext={["The following map shows the locations of researchers in the field of diabetes cyber Defence. Click on the markers to view more information about each researcher. Note, not all of these researchers are affiliated with the Initiative for Diabetes Cyber Defence (IDCD). Want to be added to the map? Contact us at hello@idcd.org.uk. If you'd like to be removed from the map, please let us know."]} />

            </div>
        </>
    );
}

export default SensorsLayer;