/**
 * 
 * Page Section Component
 * 
 * 
 */

import React from 'react';
import { useInView } from 'react-intersection-observer';


const PageSectionComponentBox = (props) => {

    const [ref, inView] = useInView({
        // Trigger just once
        triggerOnce: true,
    });

    return (
        <div ref={ref} className={`bg-slate-100 py-20 my-10 md:py-44 md:my-10 ${inView ? 'animate-fade-in-up' : ''}`}>
            <div className="w-4/5 md:w-2/3 mx-auto items-center">

                <div className="space-y-2">
                    <div className={`flex items-center`}>
                        <hr className="border-t-2 border-blue-600 w-5 mr-4" />
                        <h2 className="text-sm font-bold text-left text-blue-600">{props.subtitle}</h2>
                    </div>
                    <div>
                        <h2 className="text-2xl font-bold text-left text-gray-800">{props.title}</h2>
                    </div>
                </div>


                <p className="mt-5">
                    {props.longtext.map((item, index) => (
                        <React.Fragment key={index}>
                            {item}
                            <br />
                            {index < props.longtext.length - 1 && <br />}
                        </React.Fragment>
                    ))}
                </p>

                <div className="flex flex-col justify-center items-stretch lg:flex-row lg:space-x-5">
                    {[props.box1, props.box2, props.box3].map((box, index) => box && (
                        <div key={index} className="flex flex-col items-left mt-10 bg-slate-200 w-full p-10 space-y-5 mx-auto content-between shadow-lg">
                            <div className="flex-grow">
                                <div className="flex items-center">
                                    {/*<hr className="border-t-2 border-gray-300 w-4 mr-4" />*/}
                                    <h3 className="text-base font-bold text-left">{box.Title}</h3>
                                </div>
                                <p className="text-base text-left mt-4">{box.description}</p>
                            </div>
                            <div className="py-2">
                                <a href={box.link} className="text-blue-600 hover:text-blue-700">Learn More</a>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );

};

export default PageSectionComponentBox;