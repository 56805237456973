import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


const ResearcherMapComponent = () => {
    const [researcherData, setResearcherData] = useState([]);
    const [selectedArea, setSelectedArea] = useState('All');

    useEffect(() => {
        fetch('https://api.idcd.org.uk/api/v1/researchers')
            .then(response => response.json())
            .then(data => {
                const researchers = data.data.flatMap(country =>
                    country.institutions.flatMap(institution =>
                        institution.researchers.map(researcher => ({
                            id: researcher.id,
                            name: researcher.name,
                            category: country.name,
                            position: [institution.x_coord, institution.y_coord],
                            institution: institution.name,
                            link: researcher.link,
                            theme: researcher.themes.map(theme => (theme.name)),
                        }))
                    )
                );
                setResearcherData(researchers);
            });
    }, []);

    const filteredResearchers = selectedArea === 'All'
        ? researcherData
        : researcherData.filter(researcher => researcher.category === selectedArea);

    // Group researchers by position
    const groupedResearchers = filteredResearchers.reduce((grouped, researcher) => {
        const key = researcher.position.join(',');
        if (!grouped[key]) {
            grouped[key] = [];
        }
        grouped[key].push(researcher);
        return grouped;
    }, {});

    return (
        <div>
            <div className="flex flex-row items-center justify-between px-5 md:px-8 bg-slate-700 h-16 text-white font-bold">                
                <div>
                    Count: {filteredResearchers.length}
                </div>
            </div>
            <MapContainer center={[53.796905, -3.0111685]} zoom={6} style={{ height: "60vh", width: "100%", zIndex: "0" }}>
                <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="https://carto.com/attributions">CARTO</a>'
                />
                {Object.entries(groupedResearchers).map(([position, researchers]) => (
                    <Marker key={position} position={position.split(',').map(parseFloat)}>
                        <Popup>
                            <div className="text-center">
                                <b>{researchers[0].institution}</b>
                                <hr className="mt-1 mb-1" />
                                {researchers.map(researcher => (
                                    <div key={researcher.id}>
                                        <a href={researcher.link} target="_blank" rel="noreferrer">{researcher.name}</a>
                                        <br />
                                        <i>{researcher.theme}</i>
                                    </div>
                                ))}
                            </div>
                        </Popup>
                    </Marker>
                ))}
            </MapContainer>
        </div>
    );
}


export default ResearcherMapComponent;