import React, { useState, useEffect } from 'react';
import HeaderButtonComponent from '../header-button/HeaderButtonComponent';
import SubmenuComponent from './submenu/SubmenuComponent';
import ButtonComponent from '../button-component/ButtonComponent';

const HeaderComponent = () => {

  const handleGetInvolved = () => {
    window.location.href = '/get-involved';
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`fixed top-0 z-50 w-full`}>
      <nav
        className={`flex justify-between items-center h-16 relative nav-bar ${isScrolled ? 'scrolled' : ''}`}
        role="navigation"
      >
        <div className="flex items-center space-x-0 md:space-x-4 text-base">
          <a href="/" className="pl-8 font-bold animate-fade-in-up">Initiative for Diabetes Cyber Defence</a>

          
          <div className={`transition-all ease-in-out duration-500 flex ${isOpen ? 'absolute flex-col top-16 w-full left-0 bg-black' : 'hidden lg:flex'} lg:flex-row lg:pr-0 lg:w-auto lg:items-center z-50`}>
            
            <a href="/" className="p-5">Homepage</a>

            <SubmenuComponent
              title="Quantitative Data"
              isOpen={isOpen}
              links={[
                { href: '/vulnerability-database', text: 'Vulnerability Database' },
                { href: '/methodology', text: 'Our Methodology' },
              ]}
            />

            <SubmenuComponent
              title="Research Areas"
              isOpen={isOpen}
              links={[
                { href: '/artificial-intelligence', text: 'Artificial Intelligence' },
                { href: '/supply-chain', text: 'Supply Chain' }
              
              ]}
            />

            {/** Publications */}
            <a href="/publications" className="p-5">Publications</a>

            {/** About */}
            <a href="/about" className="p-5">About</a>
            
            {
              isOpen && ( 
                <ButtonComponent onClick={handleGetInvolved} variant="primary" className='m-5' >
                  I'm Interested
                </ButtonComponent>
              )
            }
            
          </div>

        </div>

        {!isOpen &&
          <div className="pr-8 lg:block hidden">
            <ButtonComponent onClick={handleGetInvolved} variant="primary" >
              I'm Interested
            </ButtonComponent>
          </div>
        }

        <div className="lg:hidden block">
          <button className="mobile-menu-button p-4" onClick={toggleMenu}>
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        </div>
      </nav>
    </div>
  );
};

export default HeaderComponent;