// PublicationsPost.js
import React from 'react';
import { useParams } from 'react-router-dom';
import PageTitle from '../../components/page-title/PageTitle';
import BannerComponent from '../../components/banner/BannerComponent';
import BlogSectionComponent from '../../components/blog-section-component/BlogSectionComponent';

function PublicationsPost({ posts }) {

  const { postId } = useParams();
  const post = posts.find(post => post.id === postId || post.id.toString() === postId);

  if (!post) {
    return (
      <div>Post not found</div>
    );
  }

  return (
    <>
      <PageTitle title={post.title} />

      <div>

        <BannerComponent title={post.title} image={post.image} />

        <BlogSectionComponent post={post} />

      </div>
    </>
  );
}

export default PublicationsPost;