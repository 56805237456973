/**
 * 
 * Page Section Component
 * 
 * 
 */

import React from 'react';
import { useInView } from 'react-intersection-observer';
import parse from 'html-react-parser';


const BlogSectionComponent = ({ post }) => {

    const [ref, inView] = useInView({
        // Trigger just once
        triggerOnce: true,
    });

    return (
        <div ref={ref} className={`bg-slate-100 py-20 my-10 md:py-14 md:my-10 ${inView ? 'animate-fade-in-up' : ''}`} id={post.anchor}>

            <div className="w-3/5 md:w-2/3 mx-auto block md:flex">

                <div className="md:p-5 md:m-5 w-full md:w-1/5">
                    <div className="flex flex-col items-center">
                        <img src={post.avatar} alt={post.author} className="rounded-full w-20 h-20" />
                        <p className="text-center text-gray-800 font-bold mt-3">{post.author}</p>
                        <p className="text-center text-gray-500 text-sm mt-3">{post.biography}</p>
                    </div>
                </div>

                <div className="items-center md:m-5 w-full md:w-4/5">
                    <p className="mt-5 text-justify">
                        {post && post.content && post.content.split('\n').map((item, index) => (
                            <React.Fragment key={index}>
                                {parse(item)}
                            </React.Fragment>
                        ))}
                    </p>
                    <div className="mt-5">
                        <p className="text-left text-gray-500 text-sm">
                            {post && post.date && new Date(post.date).toLocaleDateString()}
                        </p>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default BlogSectionComponent;