/**
 * 
 * ResearchMap Page
 * 
 * shows component
 * 
 */

import React from 'react';

import ResearcherMapComponent from '../../components/researcher-map/ResearcherMapComponent';
import PageSectionComponent from '../../components/page-section-component/PageSectionComponent';

function ResearcherMap() {

    return (
        <div className="ResearcherMap">

            <PageSectionComponent title="Researcher Map" subtitle="API" longtext={["The following map shows the locations of researchers in the field of diabetes cyber Defence. Click on the markers to view more information about each researcher. Note, not all of these researchers are affiliated with the Initiative for Diabetes Cyber Defence (IDCD). Want to be added to the map? Contact us at hello@idcd.org.uk. If you'd like to be removed from the map, please let us know."]} />


            <div className="mb-10">
                <ResearcherMapComponent />
            </div>

        </div>
    );
}

export default ResearcherMap;