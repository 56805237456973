import React from 'react';

const ButtonComponent = ({ 
    onClick, 
    children, 
    variant = 'primary', // 'primary' or 'secondary'
    className = '',
    ...props 
}) => {
    const baseStyles = "px-4 py-2 text-sm rounded transition-colors duration-300";
    
    const variants = {
        primary: "bg-white text-black hover:bg-opacity-90",
        secondary: "text-white hover:bg-white hover:bg-opacity-10"
    };

    return (
        <button
            onClick={onClick}
            className={`${baseStyles} ${variants[variant]} ${className}`}
            {...props}
        >
            {children}
        </button>
    );
};

export default ButtonComponent;