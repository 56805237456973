/**
 * 
 * Homepage
 * 
 * 
 */

import React from 'react';
import BannerComponent from '../../components/banner/BannerComponent';
import PageSectionComponent from '../../components/page-section-component/PageSectionComponent';
import PageTitle from '../../components/page-title/PageTitle';
import AccordionComponent from '../../components/accordion-component/AccordionComponent';


const Home = () => {

    const accordionItems = [
        {
            title: "References",
            content: (
                <div className="space-y-2">
                    <p>
                        <a>
                            {/** italic saying not complete */}
                            [1] Incomplete section.
                        </a>
                    </p>
                </div>
            )
        },
    ];

    return (

        <>
            {
                /* 
                 * 
                 * Page title.
                 * 
                */
            }
            <PageTitle title="Home" />

            <div>

                <BannerComponent title="IDCD" />

                <PageSectionComponent
                    subtitle="History and Background"
                    title="Cyber Meets The Physical World"
                    longtext={[
                        "Diabetes is a chronic metabolic condition, characterized by high levels of blood glucose which leads to serious cardiovascular, macrovascular and psychological complications over time (WHO, 2023). Whilst Type 1 diabetes (T1D) results from the body attacking insulin-producing “beta” cells, Type 2 occurs when the body resists insulin or doesn't produce enough. According to the National Health Service (NHS) England, the prevalence of diabetes in the UK continues to rise, with over 280,000 people living with T1D and approximately 3.3 million affected by T2D in 2021-22 (NHS, 2023). Although, on a national level this impacts only a few million people, on the global scale it hits a staggering 537 million people, which caused the deaths of 6.7 million people in 2021 (International Diabetes Federation, 2021). While T2D can be managed with medication, exercise, and may even be reversible, Type 1 diabetes demands a strict insulin regimen, including multiple daily injections (MDI) and glucose monitoring, with target levels typically between 4 to 6 mmol/L (72 to 108 mg/dL). If patients with T1D do not adequately control their blood glucose levels, they are at increased risk of experiencing hypoglycemia episodes, defined as abnormally low blood sugar, or hyperglycaemia, defined as abnormally high blood sugar.",
                        "The ability to sense and interpret (monitor) blood glucose levels dates back to 1965, when Ernie Adams developed the first paper reagent strip called Dextrostix. This strip used the embedded enzyme glucose oxidase (GO) to visually interpret blood glucose levels. When a blood sample containing glucose was applied to Dextrostix, the glucose reacted with the enzyme, triggering a chemical reaction that caused a colour change. Approximately five years later, in 1971, Anton H. Clemens invented the Ames Reflectance Meter (ARM), the first commercially available medical device to measure blood glucose levels. In the early 1990s, electronic Blood Glucose Monitors (BGMs) were widely used. Patients with Type 2 Diabetes (T2D) typically used them at least once a day, while those with Type 1 Diabetes (T1D) used them four to eight times daily. However, with the FDA's approval of Continuous Glucose Monitors (CGMs) in 1999, reliance on BGMs decreased, although they continue to supplement patient care today.",
                        `
                        <div id='custom-section' class='py-7 flex flex-col items-center'>
                            <img src='/FreeStyle2_Biology_and_Technology_Diagram.png' alt='Diabetes Cyber Defence' width='full' class='h-auto w-full lg:w-4/5 pb-5' />
                            <i class='text-center'>Diagram 1: The Biology and Technology of Continuous Glucose Monitors (CGMs)</i>
                        </div>
                        `,
                        "Real-time Continuous glucose monitors (rtCGMs) are small devices that attach to a patient's skin, enabling real-time blood sugar monitoring to help manage diabetes more effectively. They operate using a single motherboard and a 1.55V battery connected to a needle coated with glucose oxidase. When glucose, a sugar present in the blood, reacts with the enzyme glucose oxidase, a chemical reaction occurs that converts glucose into gluconolactone and produces hydrogen peroxide (H₂O₂) as a byproduct. The generated hydrogen peroxide can be detected electrochemically. Upon contact with an electrode within the device, hydrogen peroxide undergoes a reaction that releases electrons, resulting in a small electrical signal. The magnitude of this electrical signal is directly proportional to the concentration of glucose in the sample. This signal is converted from analogue to electrical current and transmitted to the user's mobile phone via Bluetooth Low Energy (BLE) (see Diagram 1).",
                        `
                        <div id='custom-section' class='py-7 flex flex-col items-center text-center'>
                            <img src='/Closed_Loop.png' alt='Diabetes Cyber Defence' class='h-auto w-full lg:w-4/5 pb-5' />
                            <i>Diagram 2: The Closed-Loop System (CLS)</i>
                        </div>
                        `,
                        "Further advancements in technology have led to the emergence of closed-loop systems (CLS) that automate insulin delivery. A CLS typically comprises three components: a real-time continuous glucose monitor (rtCGM), an insulin pump, and a control algorithm that calculates insulin doses based on real-time glucose readings. Diagram 2 illustrates the flow of the system. In this system, 𝑅 represents the desired blood glucose level (the reference signal), and it should match the measured process variable (𝑀𝑃𝑉) as closely as possible. 𝑆 calculates the difference between 𝑅 and 𝑀𝑃𝑉, resulting in the error 𝐸, which the controller aims to minimise. The controller, often integrated with a mobile phone application, calculates the appropriate insulin dose (𝑈) based on this error. 𝑈 is then sent to the insulin pump (the plant), which delivers insulin to adjust the patient’s blood glucose level (𝑌).",
                        `
                        <div id='custom-section' class='py-7 flex flex-col items-center text-center'>
                            <img src='/infrastructure.png' alt='Diabetes Cyber Defence' class='h-auto w-full lg:w-4/5 pb-5' />
                            <i>Diagram 3: The "Unknown" Cyber-Physical Infrastructure of Diabetes Care</i>
                        </div>
                        `,
                        "The increasing integration of interconnected components enhances the management of glucose levels; however, it also introduces vulnerabilities from a cybersecurity perspective. Academic and industry researchers have attempted to address associated issues from different perspectives but many of their approaches have been theoretical, policy-based or often neglecting the larger Modern Diabetes Management Infrastructure (MDMI). To comprehend the full scope of modern diabetes management, Diagram 3 illustrates the overall components and their interconnections. The MDMI consists of three primary layers. The first is the sensor node layer, which includes the sensor, delivery actuator, and controller. The sensor component may be a real-time continuous glucose monitor (rtCGM), an intermittent continuous glucose monitor (isCGM), or a blood glucose monitor (BGM), depending on whether the user opts for manual monitoring. Similarly, insulin delivery may be administered manually, either through smart or traditional insulin pens, or automatically through a closed-loop system (CLS) using an automated insulin pump. And lastly, the controller.",
                    ]}
                    anchor="mission-statement"
                    button="Learn More"
                    component={
                        <div className="max-w-3xl mx-auto p-4 pt-10">
                            <AccordionComponent items={accordionItems} />
                        </div>
                    }
                />

            </div>
        </>
    );
};

export default Home;