import React, { useState, useEffect } from 'react';
import ButtonComponent from '../button-component/ButtonComponent';

const CookiesComponent = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Check if user has already accepted cookies
        const cookiesAccepted = localStorage.getItem('cookiesAccepted');
        if (!cookiesAccepted) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookiesAccepted', 'true');
        setIsVisible(false);
    };

    const handleDecline = () => {
        // You might want to handle declined cookies differently
        localStorage.setItem('cookiesAccepted', 'false');
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div className="fixed bottom-0 left-0 right-0 bg-black bg-opacity-50 backdrop-blur-md z-50">
            <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8">
                <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
                    <div className="flex-1 text-white text-sm">
                        <p>
                            We use cookies to enhance your browsing experience, serve personalized content, 
                            and analyze our traffic. By clicking "Accept", you consent to our use of cookies.
                            <a 
                                href="/privacy-policy" 
                                className="underline ml-1 hover:text-gray-300"
                            >
                                Learn more
                            </a>
                        </p>
                    </div>
                    <div className="flex gap-4">
                        <ButtonComponent
                            variant="secondary"
                            onClick={handleDecline}
                        >
                            Decline
                        </ButtonComponent>
                        <ButtonComponent
                            variant="primary"
                            onClick={handleAccept}
                        >
                            Accept
                        </ButtonComponent>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CookiesComponent;