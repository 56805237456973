import React from 'react';
import { useInView } from 'react-intersection-observer';
import PageTitle from '../../components/page-title/PageTitle';
import BannerComponent from '../../components/banner/BannerComponent';
import ButtonComponent from '../../components/button-component/ButtonComponent';


const NotFound = () => {


    const [ref, inView] = useInView({
        // Trigger just once
        triggerOnce: true,
    });

    return (

        <>
            {
                /* 
                 * 
                 * Page title.
                 * 
                */
            }
            <PageTitle title="404 Not Found" />


            <div ref={ref} className={`bg-primary min-h-screen content-center ${inView ? 'animate-fade-in-up' : ''}`}>
                {/** Center content */}
                <div className="w-full mx-auto items-center relative">
                    <h1
                        className="text-center select-none w-full 
                                   text-9xl lg:text-[15rem] 
                                   leading-none text-text"
                    >
                        404
                    </h1>
                    <div className="px-1 text-center text-lg lg:text-2xl text-text font-light opacity-80 select-none">
                        The page you are looking for does not exist. Likely a typo or we are still working on it.
                    </div>
                    {/* thin line */}
                    <div className="w-[5.5em] h-[1px] bg-text opacity-50 my-4 text-center mx-auto"></div>
                    {/** back to home button */}
                    <div className="text-center pt-5">
                        <ButtonComponent variant="primary" onClick={() => window.location.href = '/'}>
                            Back to Home
                        </ButtonComponent>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NotFound;
